
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.filters {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq($from: xs) {
    flex-direction: row;
  }

  .select {
    margin-bottom: 1rem;

    &::after {
      right: 12px;
    }

    @include mq($from: xs) {
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-right: 4rem;
      }
    }
  }

  .select__input {
    min-width: 22rem; // Slightly wider than the selects longer options (ATM), ensure that they have the same width
    padding: 10px 30px 10px 15px;
    font-size: 2rem;
    font-weight: 400;
    border: 1px rgba(0, 0, 0, 0.2) solid;
    border-radius: 0;
  }
}

::v-deep {
  .reference {
    position: relative;
    display: flex;
    flex-direction: column;

    @include mq($from: s) {
      flex-direction: row;
    }

    &:hover,
    &:focus {
      .reference__visual {
        filter: grayscale(0);
      }

      .reference__icon {
        stroke: $c-main;
      }
    }
  }

  .reference__visual-container {
    margin-bottom: 2rem;

    @include mq($from: s) {
      width: 30.5%;
      margin-bottom: 0;
    }
  }

  .reference__visual {
    filter: grayscale(1);
    transition: filter 250ms $easing;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include mq($from: s) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .reference__content {
    display: flex;
    flex-direction: column;

    @include mq($from: s) {
      margin-left: 6%;
      width: 62.5%;
    }
  }

  .reference__sectors {
    display: flex;
    flex-wrap: wrap;
  }

  .reference__sector {
    margin-bottom: 1rem;
    margin-right: 3rem;
    opacity: 0.4;
  }

  .reference__details,
  [class*='reference__details--'] {
    @include liquid(font-size, 15px, 20px);

    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.46;

    &[class*='--secondary'] {
      margin-bottom: 0;
      opacity: 0.4;

      .reference__details__item {
        @include liquid(font-size, 12px, 15px);

        margin-right: 3rem;
        margin-bottom: 5px;
      }
    }
  }

  .reference__details__item {
    @include liquid(margin-right, 16px, 20px);
  }

  .reference__icon {
    width: 5rem;
    margin-top: 2rem;
    transition: stroke 250ms $easing;

    @include mq($from: s) {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: 0;
      transform: translateY(-50%);
    }

    @include mq($from: m) {
      width: 80px;
    }
  }
}
